import { DateTime } from 'luxon'

export default function formatMessageDate(createdDateRaw) {
  // Retrieve created at value and format it as a JS date to use with Luxon
  const formatDateJs = new Date(createdDateRaw.dataset.parent)

  // Format created_at locale string: fr: 14/10/1983 en: 10/14/1983
  const createdDateFormatted = DateTime.fromJSDate(formatDateJs).toLocaleString()

  createdDateRaw.innerText = dateFormatted()

  function dateFormatted() {
    // Compare created at with today
    // If it is today, display time
    // Else show full date
    if (createdDateFormatted === DateTime.now().toLocaleString()) {
      // Get local timezone name
      const getTimeZone = DateTime.local().zoneName

      // Get the time according to user timezone and change div content
      return DateTime
        .fromJSDate(formatDateJs, { zone: getTimeZone })
        .toLocaleString(DateTime.TIME_SIMPLE)
    }

    return createdDateFormatted
  }
}
