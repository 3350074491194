import '@front/cookies'

import formatMessageDate from '@front/views/supportMessage'
import cancelModal from '@front/views/cancelModal'
import dropdownMenu from '@front/views/dropdownMenu'

import('tw-elements')
document.addEventListener('DOMContentLoaded', () => {
  dropdownMenu()
  const logo_navbar = document.getElementById('logo_navbar')
  const showScrollTopButton = document.getElementById('scroll-top-button')

  document.addEventListener('scroll', () => {
    const yScroll = window.scrollY

    if (yScroll >= 342) {
      logo_navbar.classList.remove('lg:opacity-0')
      showScrollTopButton.classList.remove('opacity-0')
    } else {
      showScrollTopButton.classList.add('opacity-0')
      logo_navbar.classList.add('lg:opacity-0')
    }
  })

  const scrollTopButton = document.getElementById('scroll-top-button')

  scrollTopButton.addEventListener('click', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })

  // Show textarea characters count value
  // Get textarea element
  const textarea = document.querySelector('textarea')

  // Get maxlength attribute value and show it
  const maxLength = textarea.getAttribute('maxlength')
  const maximumContent = document.getElementById('maximum-length-content')
  maximumContent.innerText = `/${maxLength}`

  // Get default length and show it
  const currentLength = textarea.value.length
  const currentContent = document.getElementById('current-length-content')
  currentContent.innerText = `${currentLength}`

  // Update textarea characters count value while typing in
  textarea.addEventListener('input', (event) => {
    const target = event.currentTarget

    // Get length while typing
    const length = target.value.length

    // Get element and change its text content
    currentContent.innerText = `${length}`
  })

  // If section is support-request, it is the support-request view
  if (document.querySelector('#support-request')) {
    // MODAL FOR CANCEL REQUEST
    cancelModal()
  }

  // If section is support-request with messages, it is the support-request view
  if (document.querySelector('#support-messages')) {
    // Retrieve div to display created at
    const createdDateRaw = document.getElementById('created-at')
    if (createdDateRaw) {
      formatMessageDate(createdDateRaw)
    }
  }
})
