export default function dropdownMenu() {
  // Header navbar
  const dropdownMenu = document.getElementById('dropdown-button')
  const closeDropdownMenu = document.getElementById('close-dropdown-button')
  const classList = document.getElementById('mobile-menu').classList
  const closeDropdownItemMenu = document.getElementsByClassName('close-dropdown-item-menu')

  dropdownMenu.addEventListener('click', () => {
    classList.remove('invisible')
    classList.remove('opacity-0')
  })

  function changeDropdownMenuClass() {
    classList.add('invisible')
    classList.add('opacity-0')
  }

  closeDropdownMenu.addEventListener('click', () => {
    changeDropdownMenuClass()
  })

  Array.from(closeDropdownItemMenu).forEach((item) => {
    item.addEventListener('click', () => {
      changeDropdownMenuClass()
    })
  })
}
