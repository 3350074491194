// MODAL FOR CANCEL REQUEST
export default function cancelModal() {
  // Retrieve button to open the cancel modal
  const buttonOpenCancelModal = document.getElementById('open-cancel-modal')

  // Retrieve modal
  const modalCancel = document.getElementById('modal-cancel').classList

  // Retrieve header
  const headerClassList = document.getElementById('header').classList

  // Retrieve button to close or cancel opening modal
  const allClosingButton = document.getElementsByClassName('button-close-modal')

  // When clicking we change class to show modal
  buttonOpenCancelModal.onclick = () => {
    modalCancel.remove('invisible')
    modalCancel.remove('opacity-0')
    headerClassList.remove('sticky')
  }

  // When clicking we change class to hide modal
  Array.from(allClosingButton).forEach((btn) => {
    btn.addEventListener('click', () => {
      modalCancel.add('invisible')
      modalCancel.add('opacity-0')
      headerClassList.add('sticky')
    })
  })
}
